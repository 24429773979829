const modal = document.querySelector(".modal") || null
const modalButtonOpen = document.querySelectorAll('.modal-open')

    if (window.localStorage.getItem('modal-va')) {
        document.querySelector(".modal").classList.add('d-none')
    }

window.localStorage.setItem('modal-va', 'visited')


if (modal) {
    modal.addEventListener('click', e => {
        modal.classList.add('d-none')
        const video = e.target.querySelector('#modalVideo')
        if (video) {
            video.src = video.src
        }
    })
}
for (let item of modalButtonOpen) {
    item.addEventListener('click', e => {
        modal.classList.remove('d-none')
    })
}