import $ from 'jquery'

/**
 * Setup Top Menu
 */

/**
 * Open or close passed element
 *
 * @param {HTMLElement} element The element of top-nav
 */
var toggleNav = function (element) {
  var submenu, className

  if (!element) {
    return
  }

  submenu = !element.classList.contains('top-nav')

  if (submenu && !element.classList.contains('top-nav__item')) {
    return
  }

  className = submenu ? 'top-nav__item--open' : 'top-nav--open'
  const navItems = document.querySelectorAll('.' + className)
  if (navItems) {
    for (const i of navItems) {
      i.classList.remove(className)
    }
  }
  if (!element.classList.contains(className)) {
    element.classList.add(className)
    return
  }

  element.classList.remove(className)
}

var topNavs = Array.from(document.querySelectorAll('.top-nav'))

topNavs.forEach(function (item) {
  item.addEventListener('click', function (event) {
    var element = event.target

    while (element && element !== item) {
      if (element.classList.contains('top-nav__toggle')) {
        toggleNav(element.parentElement)
        event.stopPropagation()
        event.preventDefault()

        return
      }

      element = element.parentElement
    }
  })
})

/**
 * Make smooth scroll when clicked on top-nav elements
 */
// Select all links with hashes
$('a[href*="#"]')
// Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function (event) {
    // On-page links
    if (event.target.parentElement.classList.contains('top-nav__item--has-submenu')) {
      return
    }

    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
      location.hostname === this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash)
      var headerHeight = 0

      if ($(document).width() >= 960) {
        headerHeight = $('#header').height()
      }

      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')

      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault()
        $('html, body').animate({
          scrollTop: target.offset().top - headerHeight
        }, 1000, function () {
          // Callback after animation
          // Must change focus!
          // var target = $(target);
          target.focus()
          if (target.is(':focus')) { // Checking if the target was focused
            return false
          } else {
            target.attr('tabindex', '-1') // Adding tabindex for elements not focusable
            target.focus() // Set focus again
          };
        })
      }
    }
  })

/**
 * When clicked on the page, and there is open top-nav item then close it
 */
document.addEventListener('click', function () {
  var opened = document.querySelectorAll('.top-nav__item--open, .top-nav--open')
  if (!opened) {
    return
  }

  opened = Array.from(opened)

  opened.forEach(function (item) {
    item.classList.remove('top-nav__item--open')
    item.classList.remove('top-nav--open')
  })
})
