import '../styles/main.scss'

import heroResize from './heroResize'
import * as moduleLoader from './moduleLoader'
import './topNav'
import './scrollUp'
import './modal'
import AOS from 'aos'

var hero = document.getElementById('main-hero')
if (hero) {
  heroResize(hero)
  window.addEventListener('resize', () => heroResize(hero))
}

AOS.init()
AOS.refresh(true)

moduleLoader.initialize()
