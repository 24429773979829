export async function load (name) {
  return import(/* webpackChunkName: "mi" */ './' + name + '.init.js')
}

export function initialize (element) {
  element = element || document
  element.querySelectorAll('[data-module-init]').forEach(async element => {
    var initMod = await load(element.dataset.moduleInit)

    if (initMod.initialize) {
      initMod.initialize(element)
    }
  })
}
