export default function heroResize (hero) {
  if (!hero) {
    return
  }

  var bg = hero.querySelector('.hero__embed-yt')
  var w = hero.offsetWidth
  var h = hero.offsetHeight

  if (!bg) {
    return
  }

  if (w / h > 16 / 9) {
    bg.style.width = w + 'px'
    bg.style.height = w / 16 * 9 + 'px'
    bg.style.left = '0px'

    const bgHeight = bg.offsetHeight
    bg.style.top = -(bgHeight - h) / 2 + 'px'

    return
  }

  bg.style.width = h / 9 * 16 + 'px'
  bg.style.height = h + 'px'
  bg.style.top = '0px'

  const bgWidth = bg.offsetWidth
  bg.style.left = -(bgWidth - w) / 2 + 'px'
}
