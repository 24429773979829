import $ from 'jquery'

var links = Array.from(document.querySelectorAll('.scroll-up'))

var lastScrollY = 0
var visible = false

links.forEach((link) => {
  link.addEventListener('click', (e) => {
    toggleButtons(false)
    window.removeEventListener('scroll', onScroll)
    lastScrollY = 0

    $('html, body').animate({ scrollTop: 0 }, 1000, null, () => {
      window.addEventListener('scroll', onScroll)
    })
  })
})

var toggleButtons = function (show) {
  show = show || false

  if ((show && visible) || (!show && !visible)) {
    return
  }

  links.forEach((link) => {
    if (show) {
      link.classList.add('scroll-up--visible')
      return
    }

    link.classList.remove('scroll-up--visible')
  })

  visible = show
}

var onScroll = function () {
  // page is not big
  if ((document.documentElement.clientHeight * 2) > document.body.clientHeight) {
    return
  }

  if (window.scrollY < (document.documentElement.clientHeight / 2)) {
    toggleButtons(false)
    return
  }

  // scrolling down
  if (window.scrollY > lastScrollY) {
    lastScrollY = window.scrollY
    toggleButtons(false)
    return
  }

  // scrolling up
  if (window.scrollY + 100 < lastScrollY) {
    lastScrollY = window.scrollY
    toggleButtons(true)
  }
}

window.addEventListener('scroll', onScroll)

export default {
  show: toggleButtons.bind(null, [true]),
  hide: toggleButtons.bind(null, [false])
}
